#privacy-policy {
	padding-top: 120px;
	font-size: 16px;
}
#privacy-policy h1 {
	font-size: 38px;
}

#privacy-policy h2 {
	font-size: 30px;
}

#privacy-policy hr {
	width: 100%;
}

#privacy-policy .address-title {
	font-weight: bold;

}

#privacy-policy .paragraph {
	font-weight: bold;
}

#privacy-policy .float-right {
	float: right;
}

#privacy-policy ul {
	margin-top: 12px;
}

#privacy-policy li {
	list-style: circle inside;
	margin-bottom: 8px;
}