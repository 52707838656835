#stage {
    width: 160px;
    height: 220px;
    margin: 0 auto 0 auto;
    perspective: 1000px;
}
.spinner div {
    position: absolute;
    width: 160px;
    height: 160px;
   /*border: 2px solid #ccc;*/
    background: rgb(245, 245, 245);
    /*background: linear-gradient(to right, rgba(0, 170, 51, 0.8) 0%, rgba(0, 221, 51, 0.8) 100%);*/
    box-shadow: inset 0 0 20px rgba(0,0,0,0.2);
    text-align: center;
    line-height: 160px;
    font-size: 18px;
    font-weight: 800;
    margin: 0 auto 0 auto;
    border-radius: 4px;
  
}

.spinner .logo {
	width: auto;
    height: 148px;
	overflow: hidden;
    vertical-align: top;
    padding-top: 14px;
  
}


.spinner .face1 { 
   /* background-color: white; */
    -webkit-transform: translateZ(80px);
    -moz-transform: translate(80px);
    -ms-transform: translateZ(80px);
    -o-transform: translate(80px);
    transform: translateZ(80px);
}
.spinner .face2 { 
    background: linear-gradient(to bottom, rgba(0, 170, 51, 0.6) 0%, rgba(0, 221, 51, 0.6) 100%);
    color: white;
    -webkit-transform: rotateY(90deg) translateZ(80px);
    -moz-transform: rotate(90deg) translateZ(80px); 
    -ms-transform: rotateY(90deg) translateZ(80px);
    -o-transform: rotate(90deg) translateZ(80px);  
    transform: rotateY(90deg) translateZ(80px); 
}
.spinner .face3 { 
    -webkit-transform: rotateY(90deg) rotateX(90deg) translateZ(80px);
    -moz-transform: rotateY(90deg) rotateX(90deg) translateZ(80px);
    -ms-transform: rotateY(90deg) rotateX(90deg) translateZ(80px); 
    -o-transform: rotateY(90deg) rotateX(90deg) translateZ(80px);
    transform: rotateY(90deg) rotateX(90deg) translateZ(80px); 
}
.spinner .face4 { 
    background-color: rgba(255, 253, 253, 0.8);
   /* background: linear-gradient(to bottom left, rgba(255, 255, 255, 0.8) 40%, rgba(0, 221, 51, 0.8) 100%);*/
    color: #00a233;
    -webkit-transform: rotateY(180deg) rotateZ(90deg) translateZ(80px); 
    -moz-transform: rotateY(180deg) rotateZ(90deg) translateZ(80px); 
    -ms-transform: rotateY(180deg) rotateZ(90deg) translateZ(80px);
    -o-transform: rotateY(180deg) rotateZ(90deg) translateZ(80px);  
    transform: rotateY(180deg) rotateZ(90deg) translateZ(80px); 
}
.spinner .face5 { 
    -webkit-transform: rotateY(-90deg) rotateZ(90deg) translateZ(80px);
    -webkit-moz: rotateY(-90deg) rotateZ(90deg) translateZ(80px);  
    -ms-transform: rotateY(-90deg) rotateZ(90deg) translateZ(80px);
    -o-transform: rotateY(-90deg) rotateZ(90deg) translateZ(80px);  
    transform: rotateY(-90deg) rotateZ(90deg) translateZ(80px); 
}
.spinner .face6 { 
    /*box-shadow: inset 0 0 20px #00dd33;*/
    background: rgba(2, 228, 54, 0.6);
    color: rgba(30, 117, 56, 1.0);
    -webkit-transform: rotateX(-90deg) translateZ(80px);
    -moz-transform: rotateX(-90deg) translateZ(80px);  
    -ms-transform: rotateX(-90deg) translateZ(80px);
    -o-transform: rotateX(-90deg) translateZ(80px);  
    transform: rotateX(-90deg) translateZ(80px); 
}

.spinner {
    -webkit-animation: spincube 12s ease-in-out infinite;
    animation: spincube 12s ease-in-out infinite;
    -webkit-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform-origin: 80px 80px 0;
    -moz-transform-origin: 80px 80px 0;
    -ms-transform-origin: 80px 80px 0;
    -o-transform-origin: 80px 80px 0;
    transform-origin: 80px 80px 0;
}

@-moz-keyframes spincube {
    16%      { -moz-transform: rotateY(-90deg);                }
    33%      { -moz-transform: rotateY(-90deg) rotateZ(90deg); }
    50%      { -moz-transform: rotateY(180deg) rotateZ(90deg); }
    66%      { -moz-transform: rotateY(90deg) rotateX(90deg);  }
    83%      { -moz-transform: rotateX(90deg);                 }
}

@-webkit-keyframes spincube {
    16%      { -webkit-transform: rotateY(-90deg);                }
    33%      { -webkit-transform: rotateY(-90deg) rotateZ(90deg); }
    50%      { -webkit-transform: rotateY(180deg) rotateZ(90deg); }
    66%      { -webkit-transform: rotateY(90deg) rotateX(90deg);  }
    83%      { -webkit-transform: rotateX(90deg);                 }
}
@-o-keyframes spincube {
    16%      { -o-transform: rotateY(-90deg);                }
    33%      { -o-transform: rotateY(-90deg) rotateZ(90deg); }
    50%      { -o-transform: rotateY(180deg) rotateZ(90deg); }
    66%      { -o-transform: rotateY(90deg) rotateX(90deg);  }
    83%      { -o-transform: rotateX(90deg);                 }
}

@keyframes spincube {
    16%      { -ms-transform: rotateY(-90deg); transform: rotateY(-90deg); }
    33%      { -ms-transform: rotateY(-90deg) rotateZ(90deg); transform: rotateY(-90deg) rotateZ(90deg); }
    50%      { -ms-transform: rotateY(180deg) rotateZ(90deg); transform: rotateY(180deg) rotateZ(90deg); }
    66%      { -ms-transform: rotateY(90deg) rotateX(90deg); transform: rotateY(90deg) rotateX(90deg); }
    83%      { -ms-transform: rotateX(90deg); transform: rotateX(90deg); }
}