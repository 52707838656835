#imprint, #privacy-poliy {
	padding-top: 120px;
	font-size: 16px;
}
#imprint h1 {
	font-size: 38px;
}

#imprint h2 {
	font-size: 30px;
}

#imprint hr {
	width: 100%;
}

#imprint .address-title {
	font-weight: bold;

}

#imprint .paragraph {
	font-weight: bold;
}

#imprint .flowRight {
	float: right;
}
