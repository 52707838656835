
@media (max-width: 991px) {
    #features .features-item {
        min-height: 100px !important;
        margin-bottom: 24px;
    }
    .jobs-container {
        margin-top: 194px;
    }
   
    .company-intro-container {
        margin-top: 2rem;
        word-break: break-all;
        background-color: rgba(0, 100, 0, 0.837);
    }
      
}


@media (max-width: 768px) {
    #menu.navbar-default {
        background-color: #fff !important;
        border-color: rgba(231, 231, 231, 0) !important;
        box-shadow: 0 0 10px rgba(0,0,0,0.15) !important;
    }
    #menu.navbar-default .navbar-nav > li > a {
        color: #555 !important;
    }

    .intro {
        background-image: url('../img/landingpage/landingpage-mobile.jpg');
        background-position: center center;
        background-size: cover;
    }
    .intro h1 {
        font-size: 32px;
    }
    #about img {
		margin: 30px 0;
	}
    #dest-details {
        padding-bottom: 2em;
    }
    .dest-details-content-box {
        min-height: 500px;
    }
    #features {
        background: url(../img/map/map-mobile.jpg);
    }
   /* #menu {
        padding: 5px !important;
    } */
      
}

@media (max-width: 576px) {
    .jobs-container {
        margin-top: 168px;
    }
    .contact-info {
        margin-top: 4rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

}
