	.marquee {
		max-width: 100vw; /* iOS braucht das */
		white-space: nowrap;
		overflow: hidden;
        width: 90%;
        margin: 0 auto 0 auto;
		/* hier evtl. noch font-size, color usw. */
	}

	.marquee div {
		display: inline-block;
		padding-left: 105%; /* die zusätzlichen 5% erzeugen einen verzögerten Start und vermeiden so ein Ruckeln auf langsamen Seiten */
		animation: marquee 10s linear infinite;
        color: white;
        font-size: 18px;
        text-transform: uppercase;
	}

	/* Optional: mouseover (oder Tipp auf dem Touchscreen) pausiert die Laufschrift */
	.marquee div:hover {
		animation-play-state: paused; 
	}

	/* Make it move */
	@keyframes marquee {
		0%   { transform: translateX(0); }
		100% { transform: translateX(-100%); }
	}

  

    .dot {
        height: 10px;
        width: 10px;
        background-color: rgb(203, 250, 176);
        border-radius: 50%;
        border: 0px; 
        display: inline-block;
        margin: 0 10px 0 10px 
    }
   
