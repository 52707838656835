.ticker-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
   /* background: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.8) 0%, #9af282b7 100%);*/
   background: -webkit-linear-gradient(45deg, rgba(0, 0, 0, 0.527) 0%, #010201b7 100%);
    border-radius: 12px;
    padding: 8px;
    margin-bottom: 8px;
  }

.ticker {
    position: relative;
    font-family: 'Oswald', sans-serif;
  
    width: 120px;
    height: 120px;
    padding: 40px 0;
    /* background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%); */
    background: linear-gradient(to right, #00a233 0%, #00dd33 100%);
    color: #fff;
    box-shadow: 10px 10px 10px rgb(0 0 0 / 5%);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    text-align: center;
    flex-direction: column;

}

.ticker-wrapper h3 {
    margin: 0 0 8px 0;
    font-size: 1.65em;
   /* color: darkgreen; */
   color: whitesmoke;
    text-transform: uppercase;
}



.ticker p span {
    font-size: 1.8em;
}

.ticker h3 {
    font-weight: 600;
    text-transform: none;
    font-size: 22px;
    color:#333;

}