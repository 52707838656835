@media (max-width: 768px) {
    #dest-details {
        background-size: cover;
        background-position: center top;
        background-repeat: no-repeat;
        min-height: 100%;
    }
      
}
#dest-details {
    background-color: whitesmoke; 
    min-height: 100vh;
    overflow: hidden;
    background-size:cover;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    -webkit-overflow-scrolling: touch;
}

#dest-details .dest-details-overlay {
    min-height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.24);
}

#dest-details-header {
    padding: 1em 14px 0 14px;
}
#dest-details-header img.spinning-logo   {
    animation:spin 2s linear infinite;
    -webkit-animation:spin 2s linear infinite;
    -moz-animation:spin 2s linear infinite;
    max-width: 150px;
    z-index: 1005;
}


#dest-details-header h1 {
    letter-spacing: 4px;
    text-Shadow: 2px 2px 2px darkgreen;
    text-transform: uppercase;
    background: rgba(46, 139, 86, 0.589);
    border-top: 3px solid whitesmoke;
    border-bottom: 3px solid whitesmoke;
    font-weight: 700;;
    padding: 8px 0;
}

@-moz-keyframes spin { 50% { -moz-transform: rotateY(90deg); } }
@-webkit-keyframes spin { 50% { -webkit-transform: rotateY(90deg); } }
@keyframes spin { 50% { -webkit-transform: rotateY(90deg); transform:rotateY(90deg); } }

.dest-details-content-box {
    min-height: 500px;
    background-color: rgba(0, 0, 0, 0.637);
    padding: 1em;
    margin-bottom: 1em;
    box-shadow: 2px 2px 2px green;
    /* border-right: 4px solid green; */
    /* border-bottom: 4px solid green; */
    border-radius: 8px;
}
.dest-details-content-box h2 {
    color: whitesmoke;
    font-size: 1.9em;
    letter-spacing: 1px;
    font-family: 'Arimo', sans-serif;
}
.dest-details-content-box h3 {
    font-size: 1.2em;
    color: rgba(0, 170, 51, 1);
}
.dest-details-content-box .btn {
    margin-right: 8px;;
}

.dest-details-content-box ul li {
    margin-bottom: 0.8em;
    font-size: 1.1em;
    color: beige;
}

.dest-details-content-box ul li::before {
    content: '\f00c';
    font-family: 'FontAwesome';
    color: #00a233;
    font-weight: 300;
    padding-right: 8px;
    font-size: 1.3em;
}

.dest-details-content-box img {
    margin: 8px 0;
}

.dest-details-content-box .content-box-item h4, .dest-details-content-box h4 {
    
    color: rgba(0, 170, 51, 1);
    font-size: 1.4em;
    margin: 0px 0;
    font-weight: 400;
    line-height: 30px;
    border-bottom: 1px solid rgba(0, 170, 51, 0.85);
    letter-spacing: 2px;
}
.dest-details-content-box .content-box-item div {
    color: whitesmoke;
    font-size: 1.1em;
    margin-bottom: 8px;
}

.flag-image-wrapper {
    text-align: center;
}
/*-------------------------------------------------*/




#info-card h1 {
    position: absolute;
    font-family: 'Raleway', sans-serif;
    text-transform: uppercase;
    /*background-color: rgba(0, 100, 0, 0.637);*/
    background-color: seagreen;
    top: 0px;

}

#info-card {
    position: relative;
 
}
/*
.cross-h {
    position: absolute;
    width: 100%;
    /* height: 2px; 
    border-bottom: 2px solid red;
    top: 50%;
    left: 0;
    margin-top: -2px;

}


.cross-v {
    position: absolute;
    height: 100%;
    border-left: 2px solid green;
    left: 50%;
    top:0;
    margin-left: -2px
}
*/
#info-card img {
    object-fit: none;
   
}

#info-card .image-overlay {
    position: absolute;
    
    top: 5%;
    left: 5%;
    bottom: 5%;
    right: 5%;
    background-color: rgba(0, 0, 0, 0.507);
}