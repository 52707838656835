.flip-card {
    background-color: transparent;
    width: 280px;
    height: 240px;
    perspective: 800px;
    margin: 0 auto 0 auto;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
   /* box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); */
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .flip-card-front {
    /*background-color: #bbb;*/
    color: black;
  }
  
  .flip-card-back {
    /*background-color: #2980b9; 
   background: linear-gradient(to right, rgba(0, 170, 51, 0.9) 0%, rgba(0, 221, 51, 0.9) 50%,  rgba(0, 170, 51, 0.9)100%); */
   border: 4px solid green;
    color: white;
    transform: rotateY(180deg);
    border-radius: 20px;
    background-color: beige;
   /* box-shadow: 2px 2px 2px darkgreen;*/
  }