
body, html {
	/*position: relative;*/
	font-family: 'Open Sans', sans-serif;
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
	color: rgb(100, 100, 100);
	font-weight: 400;
	width: 100% !important;
	height: 100% !important;
	scroll-behavior:smooth;
	-webkit-overflow-scrolling: touch; /* lets it scroll lazy */
	/*overflow: scroll; -webkit-overflow-scrolling: touch; */
}
h2, h3, h4 {
	font-family: 'Raleway', sans-serif;
	font-display: swap;
}
h2 {
	text-transform: uppercase;
	margin: 0 0 20px 0;
	font-weight: 800;
	font-size: 36px;
	color: #333;
}
h3 {
	font-size: 20px;
	font-weight: 600;
	color: #333;
}
h4 {
	font-size: 18px;
	color: #333;
	font-weight: 600;
}
h5 {
	text-transform: uppercase;
	font-weight: 700;
	line-height: 20px;
}
p {
	font-size: 15px;
}

p.intro {
	margin: 12px 0 0;
	line-height: 24px;
}
a {
	color: #608dfd;
	font-weight: 400;
}
a:hover, a:focus {
	text-decoration: none;
	color: #608dfd;
}
ul, ol {
	list-style: none;
}
ul, ol {
	padding: 0;
	-webkit-padding: 0;
	-moz-padding: 0;
}
hr {
	height: 2px;
	width: 70px;
	text-align: center;
	position: relative;
	background: #1E7A46;
	margin-bottom: 20px;
	border: 0;
}

.container {
	padding: 10px;
}

.mirror-icon {
    -webkit-transform: scale(-1, 1);
    -moz-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    transform: scale(-1, 1);
}

/*Language */

#language-select {
	min-width: 155px;
	padding: 5px;
	background-color: white /*rgba(244, 244, 244, 0.85)*/;

	border-bottom-right-radius:  10px;
	opacity: 0.77;
	position: fixed;
	z-Index:1000000;
	font-size: 0.85em;
}

#language-select .lang-select-title {
	font-family: 'Raleway', sans-serif;
	margin-right: 4px;
	color: darkgreen;
	text-shadow: 1px 1px 1px lightgreen;
	text-transform: uppercase;
	
}

#language-select .lang-select {
	border: none;
}

/* Navigation */
#menu {
	padding: 15px;
	transition: all 0.4s;
}
#menu.navbar-default {
	background-color: transparent;
/*	border-color: rgba(255, 255, 255, 0.863); */
	border-color: transparent;
	box-shadow: 0 0 10px rgba(0,0,0,0.15);
	color:white;
}


#menu.navbar-default.active {
	background-color:#fff;
	border-color: rgba(231, 231, 231, 0);
	box-shadow: 0 0 10px rgba(0,0,0,0.15);
	color: #555;
}

#menu a.navbar-brand {
	/*font-family: 'Raleway', sans-serif;*/
	/*font-family: 'Lato', sans-serif;*/
	font-size: 24px;
	font-weight: 700;
	color: #00a233;
	text-shadow: 1px 1px 1px lightgreen;
	text-transform: uppercase;
	font-family: 'Arimo', sans-serif;
	transition: all 0.2ms;
}

#menu a.active {
	color: green;
}

#menu.navbar-default .navbar-collapse {
	max-height: 600px;
}
#menu.navbar-default .navbar-nav > li > a {
	font-family: 'Lato', sans-serif;
	text-transform: uppercase;
	/*color: #555;*/
	/*color: rgb(255, 255, 255);*/
	color: inherit;
	font-size: 16px;
	font-weight: 400;
	padding: 8px 2px;
	border-radius: 0;
	margin: 9px 10px 0 10px;
}

#menu.navbar-default .navbar-nav > li > a.active {
	color: green;
}

#menu.navbar-default .navbar-nav > li > a.active:after {
	width: 100%;
}

#menu.navbar-default .navbar-nav > li > a:after {
	display: block;
	position: absolute;
	left: 0;
	bottom: -1px;
	width: 0;
	height: 2px;
	/*background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%); */
	background: linear-gradient(to right, #00a233 0%, #00dd33 100%);
	content: "";
	transition: width 0.2s;
}
#menu.navbar-default .navbar-nav > li > a:hover:after {
	width: 100%;
}

.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
	background-color: transparent;
}
.navbar-default .navbar-nav > .active > a:after, .navbar-default .navbar-nav > .active > a:hover:after, .navbar-default .navbar-nav > .active > a:focus:after {
	display: block !important;
	position: absolute !important;
	left: 0 !important;
	bottom: -1px !important;
	width: 100% !important;
	height: 2px !important;
	/*background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%) !important;*/
	background: linear-gradient(to right, #00a233 0%, #00dd33 100%) !important;
	content: "" !important;
	transition: width 0.2s !important;
}
.navbar-toggle {
	border-radius: 0;
}
.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
	background-color: #fff;
	/*border-color: #608dfd;*/
	border-color: #00a233;
}
.navbar-default .navbar-toggle:hover>.icon-bar {
	/*background-color: #608dfd;*/
	background-color: #00a233;
}
.section-title {
	/*margin-bottom: 70px; */
	margin-bottom: 40px;
	
}
.section-title h2 {
	position: relative;
	margin-top: 10px;
	margin-bottom: 15px;
	padding-bottom: 15px;
}
.section-title h2::after {
	position: absolute;
	content: "";
	/*background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%); */
	background: linear-gradient(to right, #00dd33 0%, #00a233 100%);
	height: 4px;
	width: 60px;
	bottom: 0;
	margin-left: -30px;
	left: 50%;
}
.section-title p {
	font-size: 18px;
}


#marquee-header div {
	animation-duration: 30s;
}

#Marquee-Wrapper {
	max-width: 720px;
	margin: 0 auto;
	margin-top: -10px;
}

.btn-custom {
	font-family: 'Raleway', sans-serif;
	text-transform: uppercase;
	color: #fff;
	background-color: #5ca9fb;
	/*background-image: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);*/
	background: linear-gradient(to left, #00dd33 0%, #00a233 100%);
	padding: 14px 34px;
	letter-spacing: 1px;
	margin: 0;
	font-size: 15px;
	font-weight: 500;
	border-radius: 25px;
	transition: all 0.5s linear;
	border: 0;
}

.intro-text .btn-custom {
	margin-top: 30px;
	font-size: 18px;
	box-shadow: 2px 2px 2px green;
}
.btn-custom:hover, .btn-custom:focus, .btn-custom.focus, .btn-custom:active, .btn-custom.active {
	color: #fff;
	background-image: none;
	background-color: #00a233;
}
.btn:active, .btn.active {
	background-image: none;
	outline: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
}
a:focus, .btn:focus, .btn:active:focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn.active.focus {
	outline: none;
	outline-offset: none;
}
/* Header Section */
.intro {
	display: table;
	width: 100%;
	padding: 0;
	/*background: url(../img/intro-bg-tmp.jpg) center center no-repeat; */
	background: url(../img/landingpage/landingpage.jpg);
	background-position: center;
	
	/*background-attachment: fixed;*/
	background-color: #e5e5e5;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
	-o-background-size: cover;
}
.intro .overlay {
	/*background: rgba(0,0,0,0.2);*/
	background: rgba(0,0,0,0.0);
}
.intro h1 {
	font-family: 'Raleway', sans-serif;
	/*color: green;*/
	color:  rgba(255, 255, 255, 0.99);
	font-size: 38px;
	font-weight: 700;
	text-transform: uppercase;
	margin: 0;
	padding: 0;
}
.intro h1 span {
	font-weight: 800;
	color: #5ca9fb;
}
.intro p {
	color: #fff;
	/*font-size: 18px;*/
	font-size: 22px;
	font-weight: 300;
	line-height: 32px;
	margin: 0 auto;
	margin-bottom: 20px;
	border-radius: 8px;
	padding: 10px;
	/*background-color: rgba(0, 0, 0, 0.50); */
	background-color:rgba(1, 39, 1, 0.8);
	box-shadow: 3px 3px 3px rgba(0, 221, 51, 0.6);
	
	
	/*background: linear-gradient(to right, rgba(86, 139, 102, 0.8) 0%, rgba(0, 221, 51, 0.8) 100%);*/
	/*background: linear-gradient(to left, rgba(255, 255, 255, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%);*/
}

.grid-container {
	padding-bottom: 180px;
	
}

.grid-item {
	background-color: rgba(0, 0, 0, 0.479);

	box-shadow: 1px 1px 1px rgba(0, 221, 51, 0.6);
	color: white;
	padding: 4px;
	border-radius: 12px;
	margin-bottom: 16px;
	min-height: 130px;

}
.grid-item h2 {
	color:rgba(0, 170, 51, 1);
	font-size: 1.4em;
	margin: 8px;
	font-weight: 400;
	line-height: 30px;
	border-bottom: 1px solid rgba(0, 170, 51, 0.85);
	letter-spacing: 2px;
}

.grid-item .text-wrapper {
	padding: 0 8px 8px 8px;
	font-size: 1.2em;
}


header .intro-text {
	/*padding-top: 350px;*/
	padding-top: 160px;
	padding-bottom: 80px;
	text-align: center;
}


header .logo-alt {
	height: 200px;
	width: auto;
	padding: 10px;
	border-radius: 8px;
	margin-bottom: 20px;
	background-color: white;
	/*box-shadow: 6px 6px 6px rgba(0, 221, 51, 0.6);
	/*border: 3px solid rgba(0, 221, 51, 0.6); */
}


/* Features Section */
#features {
	padding: 100px 0;
	background: #f6f6f6;
	background: url(../img/map/map.jpg) no-repeat center center fixed;
	background-color: #e5e5e5;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
	-o-background-size: cover;
}

.overlay-features {
	padding-bottom: 40px;
	width: 100%;
	height: 100%;
	/*background: rgba(254, 254, 254,0.6);*/
}

#features .features-item {
	
	padding: 28px 8px 8px 8px;
	border-radius: 8px;
	border-top: 4px solid rgba(0, 221, 52, 0.863);
	border-bottom: 4px solid rgba(0, 221, 52, 0.863);
	background-color: whitesmoke;
	/*background-color:rgba(0, 221, 52, 1.0);*/
	/*background-color: rgba(0, 128, 0, 0.698);
	/*background: linear-gradient(to bottom, white 95%, #00dd33 100%);*/
	min-height: 540px;
	/*box-shadow: 2px 2px 2px rgba(0, 221, 52, 0.863); */
}
#features .features-item p {
	color: black;
	/*color: rgb(61, 61, 61);*/

}
#features i.fa {
	font-size: 38px;
	margin-bottom: 20px;
	transition: all 0.5s;
	color: #fff;
	width: 100px;
	height: 100px;
	padding: 30px 0;
	border-radius: 50%;
	/*background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%); */
	background: linear-gradient(to right, #00a233 0%, #00dd33 100%);
	box-shadow: 10px 10px 10px rgba(0,0,0,.05);
}
/* About Section */
#about {
	padding: 100px 0;
}
#about h3 {
	font-size: 22px;
	margin: 0 0 20px 0;
}
#about h2 {
	position: relative;
	margin-bottom: 15px;
	padding-bottom: 15px;
}
#about h2::after {
	position: absolute;
	content: "";
	/*background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);*/
	background: linear-gradient(to right, #00a233 0%, #00dd33 100%);
	height: 4px;
	width: 60px;
	bottom: 0;
	left: 0;
}
#about .about-text li {
	margin-bottom: 6px;
	margin-left: 6px;
	list-style: none;
	padding: 0;
}
#about .about-text li:before {
	content: '\f00c';
	font-family: 'FontAwesome';
	color: #00a233;
	font-size: 11px;
	font-weight: 300;
	padding-right: 8px;
}
#about img {
	width: 520px;
	margin-top: 10px;
	background: #fff;
	border-right: 0;
	box-shadow: 0 0 50px rgba(0,0,0,0.06);
}
#about p {
	line-height: 24px;
	margin: 20px 0;
	text-align: justify;
}
/* Services Section */
#services {
	padding: 100px 0;
	/*background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);*/
	background: linear-gradient(to right, #00a233 0%, #00dd33 100%);
	color: #fff;
}
#services .service-desc {
	margin: 10px 10px 20px;
}
#services h2 {
	color: #fff;
}

#services .services-item {
	min-height: 420px;
	padding: 28px 8px 8px 8px;
	border-radius: 16px;
	background-color: darkgreen;
	margin-bottom: 24px;
}
#services .section-title h2::after {
	position: absolute;
	content: "";
	background: rgba(255,255,255,.3);
	height: 4px;
	width: 60px;
	bottom: 0;
	margin-left: -30px;
	left: 50%;
}
#services i.fa {
	font-size: 42px;
	width: 120px;
	height: 120px;
	padding: 40px 0;
	/*background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%); */
	background: linear-gradient(to right, #00a233 0%, #00dd33 100%);
	border-radius: 50%;
	color: #fff;
	box-shadow: 10px 10px 10px rgba(0,0,0,.05);
}
#services h3 {
	font-weight: 500;
	padding: 5px 0;
	color: #fff;
}
#services p {
	color: rgba(255,255,255,.95);
}
#services .service-desc {
	margin-bottom: 40px;
}
/* Destination Section */
#destinations {
	padding: 100px 0;
}

.dest-details-overlay {
	padding-top: '40rem'
}

.input-filter {
	border: none;
	font-family: inherit;
	font-size: 1.8rem;
	border-bottom: 2px solid rgba(0, 170, 51, 0.8);
	text-align: center;
}
.input-filter:focus {
	border: none;
	border-bottom: 2px solid rgba(0, 170, 51, 0.8);
	background-color:  rgba(0, 221, 51, 0.1);
}

.portfolio-items {
	min-height: 400px;
	cursor: pointer;
}
.portfolio-item {
	margin: 1px -15px 0 -14px;
	padding: 0;
}
.portfolio-item .hover-bg {
	overflow: hidden;
	position: relative;
	margin: 0;
}
.portfolio-item .hover-text {
	z-index: 1001;
	position: absolute;
	text-align: center;
	margin: 0 auto;
	color: #fff;
	padding: 1px 0;
	height: 80%;
	width: 100%;
	top:0;
	left:0;
	transition: all 0.1s;
	visibility: hidden;
}


.portfolio-item .hover-text>h4 {
	-webkit-transform: translateY(100%);
	transform: translateY(100%);
	transition: all 0.4s;
	font-size: 18px;
	letter-spacing: 1px;
	font-weight: 500;
	text-transform: uppercase;
	text-shadow: 2px 2px white;
	background-color: rgba(255, 255, 255, 0.635);
	padding: 8px;
	margin: 0;

}
.portfolio-item .hover-text .hover-content {
	background-color: rgba(0, 128, 0, 0.623);
	padding: 8px;
	font-size: 1.3em;
}

.portfolio-item .hover-text .hover-text-info-icon {
	color: whitesmoke;
	opacity: 0;
	margin: auto;
	transition: ease 0.6s;
	margin-top: 8%;
}

.portfolio-item:hover .hover-text .hover-text-info-icon {
	opacity: 0.933;
	animation: spin 1s linear infinite;
}


.portfolio-item:hover .hover-text {
	visibility: visible;
}
.portfolio-item:hover .hover-text>h4 {
	opacity: 1;
	-webkit-transform: translateY(0);
	transform: translateY(0);
}

.portfolio-item:hover img {

	-webkit-transform: scale(1.2);
	-moz-transform: scale(1.2);
	-ms-transform: scale(1.2);
	-o-transform: scale(1.2);
	transform: scale(1.2);
}

.portfolio-img-overlay {
	cursor: pointer;
	color: white;
	position: absolute;
	display: 'block';
	width: '100%';
	left: 0;
	bottom: 0;
	background-color: rgba(0, 100, 0, 0.637);
	/*background: linear-gradient(to right, rgba(0, 170, 51, 0.7) 40%, rgba(0, 221, 51, 0.7) 100%);*/
	max-height: 30%;
	width: 100%;
}

.portfolio-item:hover .portfolio-img-overlay > h3 {
	font-size: 24px;
	transition: font-size 0.4s;
}

.portfolio-img-overlay > h3 {
	text-transform: uppercase;
	color: white;
	margin-bottom: 0;
	font-size: 20px;
	letter-spacing: 2px;
}

.hover-bg img {
	width: 100%;
	height: auto;
	/* SCALE */
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
	/* VERZÖGERUNG */
	-webkit-transition: all 0.4s linear;
	-moz-transition: all 0.4s linear;
	-ms-transition: all 0.4s linear;
	-o-transition: all 0.4s linear;
	transition: all 0.4s linear;
}
.hover--bg a img:hover {
	-webkit-transform: scale(1.4);
	-moz-transform: scale(1.4);
	-ms-transform: scale(1.4);
	-o-transform: scale(1.4);
	transform: scale(1.4);
}


/* Testimonials Section */
#green-logistic {
	padding: 80px 0;
	background: #f6f6f6;
	background: url(../img/landingpage/green-logistic.jpg) center center no-repeat;
	background-attachment: fixed;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
	-o-background-size: cover;
	color: whitesmoke;
}

#green-logistic h2 {
	position: relative;
    margin-top: 10px;
    margin-bottom: 15px;
    padding-bottom: 15px;
	text-align: center;
	color: white;
	letter-spacing: 2px;
}

#green-logistic-header {
	padding-top: 16px;
}

#green-logistic i {
	color: #e6e6e6;
	font-size: 32px;
	margin-bottom: 20px;
}

.green-logistic-item {
	background-color: rgba(0, 0, 0, 0.6);
    box-shadow: 1px 1px 1px rgb(0 221 51 / 60%);
    color: white;
    padding: 8px;
    border-radius: 12px;
    margin-bottom: 16px;
   /* min-height: 240px;*/

}

.green-logistic-item h3 {
	color: rgba(0, 170, 51, 1);
    font-size: 1.4em;
    margin: 8px;
    font-weight: 400;
    line-height: 30px;
    border-bottom: 1px solid rgba(0, 170, 51, 0.85);
    letter-spacing: 2px;
	text-transform: uppercase;

}

.green-logistic-item p {
	font-size: 1.2em;
	padding: 0 8px 0 8px;
}


.green-background {
	background: linear-gradient(to right, rgba(65, 42, 42, 0.7) 0%, rgba(0, 128, 0, 0.623) 100%);	
	
}
/* Team Section */
#team {
	padding: 100px 0;
}
#team h4 {
	margin: 5px 0;
}
#team .team-img {
	width: 240px;
	height: 240px;
	object-fit: cover;
}
#team .thumbnail {
	border: 0;
	cursor: pointer;

}

#team .team-contact-container {
	padding: 5px 0 5px 0;
	cursor: pointer;
}

#team .team-contact-headline {
	text-transform: uppercase;
}

#team .team-contact-body li a {
	color: rgb(0, 71, 0);
	word-wrap: break-word;	
}

#team .team-contact-phone {
	font-size: 1.6em;
}
#team .team-contact-email {
	font-size: 1.2em;
}

#team .team-contact-divider {
	padding: 0;
	width: 80%;
	color: white;
	align-self: center;
	margin: 10px auto 10px auto;
	background: linear-gradient(to right, rgba(0, 170, 51, 0.9) 0%, rgba(0, 221, 51, 0.9) 100%);
}

#team .thumbnail .caption {
	padding: 10px 0 0 0;
	/*color: #888; */
	color: black;
}

/* Contact Section */
#contact {
	padding: 100px 0 60px 0;
	/*background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%); */
	background: linear-gradient(to right, #00a233 0%, #00dd33 100%);
	color: rgba(255,255,255,.75);
}

#contact .contact-info {
	background-color: darkgreen;
}
#contact .logo {
	height: 100px;
	width: auto;
	padding: 3px;
	background-color: white;
}
#contact .section-title {
	margin-bottom: 20px;
}
#contact .section-title p {
	font-size: 16px;
}
#contact h2 {
	color: #fff;
	margin-top: 10px;
	margin-bottom: 15px;
	padding-bottom: 15px;
}
#contact .section-title h2::after {
	position: absolute;
	content: "";
	background: rgba(255,255,255,.3);
	height: 4px;
	width: 60px;
	bottom: 0;
	left: 30px;
}

.contact-item .address  {
	cursor: pointer;
}
#contact h3 {
	color: #fff;
	/*
	margin-top: 80px;
	margin-bottom: 25px;
	padding-bottom: 20px;
	font-weight: 400;
	*/
}
.contact-headline {		
		/*content: "";*/
		/*background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%); */
		background: linear-gradient(to right, #00dd33 0%, white 100%);
		height: 4px;
		width: 100%;	
}
#contact form {
	padding-top: 20px;
}
#contact .iframe-wrapper {
	background-color: rgba(0, 0, 0, 0.2);
}

#google-iframe {
	vertical-align: center;
}

#contact .text-danger {
	color: #cc0033;
	text-align: left;
}
#contact .btn-custom {
	margin: 40px 0;
	background: transparent;
	border: 2px solid #fff;
}
#contact .btn-custom:hover {
	color: #1f386e;
	background: #fff;
}
label {
	font-size: 12px;
	font-weight: 400;
	font-family: 'Open Sans', sans-serif;
	float: left;
}
#contact .form-control {
	display: block;
	width: 100%;
	padding: 6px 12px;
	font-size: 16px;
	line-height: 1.42857143;
	color: #444;
	background-color: #fff;
	background-image: none;
	border: 1px solid #ddd;
	border-radius: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
	-webkit-transition: none;
	-o-transition: none;
	transition: none;
}
#contact .form-control:focus {
	border-color: #999;
	outline: 0;
	-webkit-box-shadow: transparent;
	box-shadow: transparent;
}
.form-control::-webkit-input-placeholder {
color: #777;
}
.form-control:-moz-placeholder {
color: #777;
}
.form-control::-moz-placeholder {
color: #777;
}
.form-control:-ms-input-placeholder {
color: #777;
}
#contact .contact-item {
	/*margin: 20px 0;*/
	margin: 0 0;
}
#contact .contact-item span {
	color: rgba(255,255,255,1);
	margin-bottom: 10px;
	display: block;
}
#contact .contact-item i.fa {
	margin-right: 10px;
}
#contact .social {
	border-top: 1px solid rgba(255,255,255,0.15);
	padding-top: 50px;
	margin-top: 50px;
	text-align: center;
}
#contact .social ul li {
	display: inline-block;
	margin: 0 20px;
}
#contact .social i.fa {
	font-size: 22px;
	width: 48px;
	height: 48px;
	padding: 12px 0;
	border: 2px solid #fff;
	color: #fff;
	border-radius: 50%;
	transition: all 0.3s;
}
#contact .social i.fa:hover {
	color: #608dfd;
	background: #fff;
}
/* Footer Section*/
#footer {
	background: #f6f6f6;
	padding: 30px 0;
}
#footer h2 {
	display:'inline-block';
	/*color: rgba(226, 226, 226, 0.904);*/
	color: darkgray;
	font-family: 'Arimo', sans-serif;
	letter-spacing: 1.5px;	
	margin-top: 20px;
	font-size: 32px;
}
#footer p {
	/*color: #888;*/
	color: rgba(0, 0, 0, 8);
	font-size: 14px;
}
#footer a {
	/*color: #608dfd;*/
	color: darkgreen;
	
}
#footer a:hover {
	border-bottom: 2px solid green;
	border-width: 100%;
	color: green;

}

.footer-column 	hr, .qr-code-wrapper hr {
	width: 100%;
	margin: 0 0 10px 0;
}
.footer-column ul li {

	font-size: 1.1em;
	
}

.footer-column .title-wrapper h3 {
	display: inline-block;

}
.footer-column .title-wrapper i {
	padding-right: 10px;
	color: green;
}
.member-list-wrapper {
	margin-bottom: 12px;
}
.member-list-wrapper img {
	width: 100px;
	height: auto;
}

.qr-code-wrapper {
	display: inline-block;
}
.qr-code-wrapper h4 {
	margin-right: 1em;
	display: inline-block;
}
.qr-code-wrapper img {
	max-width: 150px;
	height: auto;	
}

.qr-code-wrapper .qr-code-subtitle {
	font-size: 1.2em;
	color: darkgreen;

}

#footer #copyright {
	margin-top: 18px;
}

#fullscreen-modal {
	z-index: 1050;
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	background: linear-gradient(to right, rgba(0, 170, 51, 0.5) 40%, rgba(0, 221, 51, 0.5) 50%);
	border: 4px solid #00dd33;
}

.fullscreen-modal-card-wrapper {
	overflow-y: scroll;
	/*width: 80vw;*/
	height: 100vh;
	margin: 0 auto 0 auto;
	background: white;
	box-shadow: 10px 10px 10px rgba(0,0,0,.05);
	padding: 40px;
}
.modal-card-text {
	text-align: left;
	margin-bottom: 6px;
	margin-left: 6px;
	list-style: none;
	padding: 0;
}
.modal-card-text h5 {
	color: green;
}

.modal-card-text li:before {
	content: '\f00c';
	font-family: 'FontAwesome';
	color: #00a233;
	font-size: 11px;
	font-weight: 300;
	padding-right: 8px;

}
/*
.modal-card-text > h5::after {
	position: absolute;
	content: "";
	/*background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
	background: linear-gradient(to right, #00a233 0%, #00dd33 100%);
	height: 4px;
	width: 60px;
	top: 30px;
	left: 15px;
} */
.modal-card-headline > img {
	width: '33.33%';
	height: 'auto';
}
.underline {
	/*background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%); */
	background: linear-gradient(to right, #00dd33 0%, #00a233 100%);
	height: 4px;
	width: 20%;
	margin: 0 auto 8px auto;
}
.no-scroll {
	overflow: hidden;
}
/*

.glow-on-hover {
    width: 220px;
    height: 50px;
    border: none;
    outline: none;
    color: #fff;

	background: #00a233;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
}

.glow-on-hover:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

.glow-on-hover:active {
    color: #000
}

.glow-on-hover:active:after {
    background: transparent;
}

.glow-on-hover:hover:before {
    opacity: 1;
}

.glow-on-hover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
  
	background: #00a233;
    left: 0;
    top: 0;
    border-radius: 10px;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}
*/
#page-not-found {
	padding-top: 140px;
	background: url(../img/landingpage/landingpage.jpg) center center no-repeat; 
	text-align: center;
	height:100vh;
}

#page-not-found h1 {
	color: white;
}

#page-not-found i {
	color: whitesmoke;
	margin: 20px 0 20px 0;
}

#page-not-found .i-wrapper {
	padding: 18px;
	border-radius: 50%;
	background-color: rgba(0, 128, 0, 0.678);;
}
#page-not-found p {
	margin: 0 0 20px 0;
	color: whitesmoke;
	font-size: 1.5em;
}

.page-not-found-overlay {
	padding: 28px;

	margin: 0 auto 0 auto;
}

#modal {
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	background-color: transparent;
	color: #FFF;
	z-index: 2000;
	overflow: scroll;

}
