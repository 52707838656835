#jobs {
    padding-bottom: 10rem;
    background-color: white ;
    /*background: linear-gradient(to left, #00dd33 0%, #00a233 100%);*/

    background-repeat: no-repeat;
}
#jobs-container {
    background-color: #ffffff;
    background-image: url(../static/img/jobs/jobs-globalsped-4.jpg);
    background-repeat: no-repeat;
    background-position: center top;  
}
/*
.foo {
    border: 2px solid black;
    padding-bottom: 2rem;
   
}
.bar {
   border: 2px solid green;
    padding-bottom: 2rem;
}
*/
.jobs-subtitle {
    background-color: white;
    border: 8px;
}
.company-intro-container {
    margin-top: 144px;
    border-left: 4px solid rgba(0, 221, 51, 0.6);
    border-right: 4px solid rgba(0, 221, 51, 0.6);
    border-top: 4px solid rgba(0, 221, 51, 0.6);
    background-color: rgba(0, 221, 51, 0.6);
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;

}
.jobs-container {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border-left: 4px solid rgba(0, 100, 0, 0.637);
    border-right: 4px solid rgba(0, 100, 0, 0.637);
    border-top: 4px solid rgba(0, 100, 0, 0.637);
    margin-top: 144px;
    background-color: rgba(0, 100, 0, 0.637);
    
  }
.jobs-title-wrapper {
    padding: 20px 0 10px 0;
 
}
.jobs-title-wrapper > h3 {
    font-size: 40;
    color: white;
    text-transform: uppercase;

}
.jobs-text-wrapper {
    text-align: left;
    border-bottom: 4px solid rgba(0, 221, 51, 0.6);
    border-left: 4px solid rgba(0, 221, 51, 0.6);
    border-right: 4px solid rgba(0, 221, 51, 0.6);
    padding: 6px 6px 10px 6px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}
.jobs-content-wrapper {
    border-bottom: 4px solid rgba(0, 100, 0, 0.637);
    border-left: 4px solid rgba(0, 100, 0, 0.637);
    border-right: 4px solid rgba(0, 100, 0, 0.637);
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
   
    text-align: left;
    padding: 6px 6px 10px 6px;
}

.jobs-btn-wrapper {
    margin-top: 1em;
    margin-bottom: 1em;
 
}
.jobs-btn-wrapper > ul {
    max-width: 400px;
}
.jobs-btn-title {
    color: rgba(0, 100, 0, 1);
    text-transform: uppercase;
    font-size: 16px;
}
.jobs-btn {
    padding: 4px 6px;

    margin-bottom: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: left;
    white-space: normal;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;

}
ul.jobs-description-list  {
    margin-left: 2rem;
    margin-top: 2.4rem;
}
.jobs-description-list > li {

    margin-bottom: 6px;
    margin-left: 6px;
    list-style: circle;
    padding: 0;
    list-style-position: outside;
}